import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import './i18n/i18n'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.Suspense fallback="loading">
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.Suspense>
);
reportWebVitals();
