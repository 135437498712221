export  function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.jwtToken.token) {
      return { Authorization: 'Bearer ' + user.jwtToken.token };
    } else {
      return {};
    }
  }

export function resetpassword(){
  const user = JSON.parse(localStorage.getItem('passWord'))
  if (user && user.token) {
    return user.token ;
  } else {
    return {};
  }
}

