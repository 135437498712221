import { lazy, Suspense} from 'react'
import { Route, Routes } from 'react-router-dom';
import { resetpassword } from './servers/auth-header';
import Loading from './loading/loading'

const Employees = lazy(() => import('./components/login/addNewEmployees'));
const BeverageBranch = lazy(() => import('./components/login/addBeverageBranch'));
const Verify = lazy(() => import('./components/login/verificationCode'));
const Front = lazy(() => import('./components/frontPage'));
const Carbon = lazy(() => import('./components/carbonCalculate'));
const Login = lazy(() => import('./components/login'));
const Password = lazy(() => import('./components/login/forgotPassword'));
const Account = lazy(() => import('./components/login/registerAccount'));
const ResetPassword = lazy(() => import('./components/login/changPassword'));
const CompanyInformation = lazy(() => import('./components/login/companyInformation'));
const CarbonCalculate = lazy(() => import('./components/report/carbonReport/CarbonResult'));
const CarbonCalculateEN= lazy(() => import('./components/report/carbonReportEN/CarbonResult'));
const CommuteForm = lazy(() => import('./components/commuteForm'));

const App = () => {
  return (
      <Suspense fallback={<Loading/>}>
        <Routes>
          <Route path='/' element={<Front />}></Route>
          <Route path='/carbonCalculate/*' element={<Carbon />}></Route>
          <Route path='/login/*' element={<Login />}>  </Route>
          <Route path='login/password' element={<Password />}></Route>
          <Route path='login/account' element={<Account />}></Route>
          <Route path='login/company' element={<CompanyInformation />}></Route>
          <Route path={'login/resetPassword/' + resetpassword()} element={<ResetPassword />}></Route>
          <Route path='/:companyName/:department/:email' element={<Employees/>}></Route>
          <Route path='/CarbonCalculate/:companyName/:factoryName/:year' element={<CarbonCalculate />}></Route>
          <Route path='/CarbonCalculateEN/:companyName/:factoryName/:year' element={<CarbonCalculateEN />}></Route>
          <Route path='/CommuteForm/:companyName/:factoryName/:year' element={<CommuteForm/>}></Route>
          <Route path='/verify' element={<Verify/>}></Route>
          <Route path='MemberRegister/:token' element={<BeverageBranch/>}></Route>
        </Routes>
      </Suspense>
  )
}
export default App;
